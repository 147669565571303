// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/home/main/Projects/bcash4you/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("/home/main/Projects/bcash4you/src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-contact-js": () => import("/home/main/Projects/bcash4you/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/home/main/Projects/bcash4you/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("/home/main/Projects/bcash4you/src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-login-js": () => import("/home/main/Projects/bcash4you/src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-signup-js": () => import("/home/main/Projects/bcash4you/src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-tools-banners-js": () => import("/home/main/Projects/bcash4you/src/pages/tools-banners.js" /* webpackChunkName: "component---src-pages-tools-banners-js" */),
  "component---src-pages-tools-fhg-js": () => import("/home/main/Projects/bcash4you/src/pages/tools-fhg.js" /* webpackChunkName: "component---src-pages-tools-fhg-js" */),
  "component---src-pages-tools-links-js": () => import("/home/main/Projects/bcash4you/src/pages/tools-links.js" /* webpackChunkName: "component---src-pages-tools-links-js" */),
  "component---src-pages-tools-potd-js": () => import("/home/main/Projects/bcash4you/src/pages/tools-potd.js" /* webpackChunkName: "component---src-pages-tools-potd-js" */)
}

