import React, { useEffect, useReducer } from 'react';
import Cookies from 'js-cookie';

let reducer = (state, action) => {
    let newState;

    switch (action.type) {
        case 'login':
            Cookies.set('loggedAffiliate', action.affiliateId);

            newState = { ...state, affiliateId: action.affiliateId };
            break;
        case 'logout':
            Cookies.set('loggedAffiliate', '');

            newState = { ...state, affiliateId: null };
            break;
        default:
            newState = { ...state };
    }
    return newState;
};

const cookie = Cookies.get('loggedAffiliate');
const initialState = {
    affiliateId: cookie && cookie.trim() !== '' ? cookie : null,
};
const AppContext = React.createContext(initialState);

function App(props) {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <AppContext.Provider value={{ state, dispatch }}>
            {props.children}
        </AppContext.Provider>
    );
}

export { AppContext, App };
